import React from 'react'
import { connect } from 'react-redux'

import { loginUser, selectIsLoggedIn, hasAuthCheckFinished } from '@nike/oauth-client'
import Spinner from '../components/Spinner.js'

const mapProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  loginFinished: hasAuthCheckFinished(state)
})

const LoginRequired = connect(
  mapProps,
  { loginUser }
)(({ isLoggedIn, loginFinished, title, loginUser, children }) => {
  if (!loginFinished) {
    return <Spinner title={title} noHeader />
  }

  if (!isLoggedIn) {
    return (
      <div>
        <h1>You must be logged in to view this page</h1>
        <button onClick={loginUser}>Login</button>
      </div>
    )
  }

  return React.Children.only(children)
})

// eslint-disable-next-line react/display-name
const requireLogin = title => WrappedComponent => props => (
  <LoginRequired title={title}>
    <WrappedComponent {...props} />
  </LoginRequired>
)

export { requireLogin }
export default LoginRequired
