import ApolloClient from 'apollo-boost';
import { getGlobalHeader } from '../util/http.js'
import { graphqlUri } from '../config.js';

const client = new ApolloClient({
  uri: graphqlUri,
  request: (operation) => {
    const { Authorization } = getGlobalHeader();
    operation.setContext({
      headers: { authorization: Authorization }
    })
  }
});

export default client;