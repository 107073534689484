import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { useRoutes, Link } from 'raviger'
import { initializeUserAuth, hasAuthCheckFinished, selectLoginError } from '@nike/oauth-client'
import { Button, NavBar } from '@nike/epic-react-ui'

import routes from './routes.js'
import NotFoundPage from './NotFoundPage.js'
import OfflineBanner from '../offline/OfflineBanner.js'
import LoginRequired from '../auth/LoginRequired.js'
import Spinner from '../components/Spinner.js'
import Error from '../components/Error.js'
import config from '../config.js'
import './App.css'

const navBarLinks = []

const App = ({ initializeUserAuth, loginError, loginFinished }) => {
  useEffect(() => initializeUserAuth())

  const routeResult = useRoutes(routes) || <NotFoundPage />

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={initializeUserAuth}>Login</Button>
      </div>
    )
  }

  if (!loginFinished) {
    return <Spinner large />
  }

  return (
    <div>
      <OfflineBanner />
      <LoginRequired>
        <>
          <NavBar
            name={config.appName}
            logo='/logo.png'
            rootPath='/'
            routes={navBarLinks}
            RouterLink={Link}
            slackURL='slack://channel?team=T0XT0BQ3V&id=CNASTK8N6'
          />
          <div style={{ margin: '1rem' }}>{routeResult}</div>
        </>
      </LoginRequired>
    </div>
  )
}

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state)
  }),
  { initializeUserAuth }
)(App)
