import merge from 'deepmerge'

const base = {
  appName: 'Cluster Select',
  oAuth: {
    client_id: 'nike.hyperflow.cluster-select',
    response_type: 'id_token token',
    scope: 'profile openid email'
  }
}

const dev = merge(base, {
  apiUrl: '/dev',
  oAuth: {
    redirect_uri: window.location.origin,
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize'
  }
})

const prod = merge(base, {
  apiUrl: '/prod',
  oAuth: {
    redirect_uri: 'https://callysto.nikecloud.com',
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize'
  }
})

const isProd = window.location.origin.includes('//callysto.nikecloud.com')
const config = isProd ? prod : dev

export const graphqlUri = `https://cluster-api${isProd ? '' : '.preprod'}.callysto.nikecloud.com/v1/graphql`

export default config
