/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Section, Spinner, Button, Card } from '@nike/epic-react-ui'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import './ClusterSelection.css'

/*
  id: ID!,
  name: String!,
  owner: String!,
  href: String!,
  hasAccess: Boolean!
  accessHint: String!,
*/
const GET_CLUSTERS = gql`
  {
    clusters{
      id
      name
      owner
      href
      accessHint
      hasAccess
    }
  }
`;

function launchCallysto(href) {
  window.location.href = href
}

export const ClusterList = ({ data: { clusters } }) => (
  <Section title='Clusters:' className="card-section">
    {
      clusters.map(({ id, name, owner, href, accessHint, hasAccess }) => (
        <Card
          className="card"
          key={id}
          title={name}
          subtitle={owner !== '' ? `owner: ${owner}` : ''}
          description={accessHint}
          height="200px"
          width="200px"
        >
          {hasAccess ? <Button className='btn-launch' onClick={() => launchCallysto(href)}>Launch</Button> : null}
        </Card>
      ))
    }
  </Section>
)

function ClusterSelection() {
  const { loading, error, data } = useQuery(GET_CLUSTERS);

  if (loading) return <Section title='Clusters:'><Spinner /> </Section>;
  if (error) return <Section title='Clusters:'><p><i>Error connecting to the REST service</i></p> </Section>;

  return (<ClusterList data={data} />)
}

export default ClusterSelection