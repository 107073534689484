/* eslint-disable react/jsx-no-target-blank*/

import React, { Component } from 'react'
import { connect } from 'react-redux'

import ClusterSelection from './ClusterSelection.js'
import './HomePage.css'

export class HomePage extends Component {
  render() {
    return (
      <div className='container'>
        <ClusterSelection />
      </div>
    )
  }
}

export default connect(
  state => ({ user: state.auth }),
  null
)(HomePage)
