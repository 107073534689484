import { Map, OrderedMap, fromJS } from 'immutable'

import { addNotification, removeNotification, clearNotifications } from './actions.js'

const defaultState = Map({
  notifications: OrderedMap({})
})

export default function notifications(state = defaultState, action) {
  state = fromJS(state)

  switch (action.type) {
    case addNotification.type:
      state = state.setIn(['notifications', action.payload.id], action.payload)
      while (state.get('notifications').size > 2) {
        state = state.deleteIn(['notifications', state.get('notifications').first().id])
      }
      return state
    case removeNotification.type:
      return state.deleteIn(['notifications', action.payload])
    case clearNotifications.type:
      return state.set('notifications', OrderedMap({}))
    default:
      return state
  }
}

export const selectNotifications = state => state.notifications.get('notifications')
