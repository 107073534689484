import React from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks';

import App from './App.js'
import client from "./apollo-client.js"

const Root = ({ store }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>
)

export default Root
