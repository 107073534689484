import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import Root from './root/Root.js'
import { unregister } from './serviceWorker.js'
import { watchOnlineState } from './offline/actions.js'

import configureStore from './root/store.js'

export const store = configureStore()

watchOnlineState(store)

// React stuff
ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
